<template>
  <footer class="bg-gray-200 mt-56 py-10 px-14">
    <div class="container grid grid-cols-1 md:grid-cols-4 mb-10 gap-6">
      <div class="flex flex-col items-center md:items-start md:col-span-2 mb-10 md:mb-0">
        <h2 class="text-4xl mt-0 font-black uppercase text-gray-900 text-center md:text-start">Propeller Festival</h2>
        <p class="text-center md:text-start">Eine Veranstaltung des BDKJ Allgäu Oberschwaben.</p>
        <small class="text-sm text-darkRed">Mit Herz geplant von eurem Propeller Team!</small>
      </div>
      <div class="flex flex-col items-center space-y-3 md:items-start mb-10 md:mb-0">
        <h2 class="mt-0">Festival</h2>
        <NuxtLink class="text-fontColor hover:text-darkRed" to="/teilnahme">AGBs</NuxtLink>
        <NuxtLink class="text-fontColor hover:text-darkRed" to="/anfahrt">Anfahrt</NuxtLink>
        <NuxtLink class="text-fontColor hover:text-darkRed" to="/faqs">FAQs</NuxtLink>
      </div>
      <div class="flex flex-col items-center space-y-3 md:items-start mb-10 md:mb-0">
        <h2 class="mt-0">Über uns</h2>
        <NuxtLink class="text-fontColor hover:text-darkRed" to="/datenschutz">Datenschutz</NuxtLink>
        <NuxtLink class="text-fontColor hover:text-darkRed" to="/impressum">Impressum</NuxtLink>
        <NuxtLink class="text-fontColor hover:text-darkRed" to="/kontakt">Kontakt</NuxtLink>
      </div>
    </div>
    <div class="container flex justify-between items-center flex-col sm:flex-row gap-3">
      <NuxtLink to="mailto:info@propellerfestival.de" target="_blank" class="flex space-x-2 items-start text-fontColor hover:text-darkRed">
        <UIcon name="i-heroicons-envelope" class="size-7" />
        <label>info@propellerfestival.de</label>
      </NuxtLink>
      <div class="flex items-center space-x-4">
        <NuxtLink to="https://www.youtube.com/@jurefallgauoberschwaben4642" target="_blank" id="youtube" class="text-fontColor hover:text-darkRed">
          <UIcon name="bxl:youtube" class="size-7" dynamic />
        </NuxtLink>
        <NuxtLink to="https://www.instagram.com/propellerfestival" target="_blank" id="instagram" class="text-fontColor hover:text-darkRed">
          <UIcon name="bxl:instagram" class="size-7" dynamic />
        </NuxtLink>
      </div>
    </div>
  </footer>
</template>

<style scoped></style>
